$(() => {
  function setupGoogleMapAutoComplete() {
    const input = document.getElementById('position-location-search');
    if (!input) return;
    const searchBox = new google.maps.places.Autocomplete(input);

    searchBox.addListener('place_changed', () => {
      const place = searchBox.getPlace();

      if (place === undefined) {
        $('#position-location-search-lat').val('');
        $('#position-location-search-lng').val('');
        return;
      }

      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      $('#position-location-search-lat').val(location.lat);
      $('#position-location-search-lng').val(location.lng);
    });
  }

  google.maps.event.addDomListener(window, 'load', setupGoogleMapAutoComplete);

  function searchPositions(radius) {
    const title = $('#position-title-search').val();
    let lat = $('#position-location-search-lat').val();
    let lng = $('#position-location-search-lng').val();
    const location = $('#position-location-search').val();

    if (location.length === 0) {
      lat = '';
      lng = '';
    }

    searchQuery = {
      page: 1,
      radius: radius !== 'all' ? radius || 50 : null, // Remove radius if user want to search all
      title: encodeURIComponent(title),
      lat: encodeURIComponent(lat),
      lng: encodeURIComponent(lng),
      geo: encodeURIComponent(location),
    };

    if (typeof (isNewUrl) !== 'undefined' && isNewUrl && searchBaseUrl) {
      updateSearchQueryStringParamsWithNewUrl(searchBaseUrl, searchQuery);
    } else {
      updateSearchQueryStringParams(slug, searchQuery);
    }
  }

  $('.search-position').click((e) => {
    e.preventDefault();
    searchPositions($('#search-radius').val());
  });

  $('#position-title-search, #position-location-search').keypress((e) => {
    const key = e.which;
    if (key == 13) {
      searchPositions($('#search-radius').val());
      return false;
    }
  });

  if (query.radius) {
    $('#search-radius').selectpicker('val', query.radius);
  } else {
    $('#search-radius').selectpicker('val', 'all');
  }

  if (query.geo) {
    $('#position-location-search').val(query.geo);
  } else {
    $('#position-location-search').val('');
  }
});
